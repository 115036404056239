import Vimeo from '@u-wave/react-vimeo';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { pageMount, pageWatchLoaded } from '../state/page';

const WatchPage = ({ onMount = () => {}, onPageWatchLoaded = () => {} }) => {
  const ref = useRef(null);
  const vimeoRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [videoMuted, setVideoMuted] = useState(null);
  const [showMuted, setShowMuted] = useState(false);

  /* Effect handling page mount */
  useEffect(() => {
    onMount();

    gsap.set(ref.current, {
      opacity: 0,
    });

    if (loading === true) return;

    setTimeout(onPageWatchLoaded, 2500);

    const tl = gsap.timeline();

    gsap.set(ref.current, {
      x: window.innerWidth - window.innerWidth * 0.2,
      y: window.innerHeight,
      scale: 0,
      opacity: 1,
    });

    tl.to(ref.current, {
      duration: 3,
      scale: 1,
      x: 0,
      y: 0,
      ease: 'power1.in',
      rotate: 358 * 2,
      onComplete: () => {
        setShowMuted(true);
      },
    });
  }, [loading]);

  const handleOnMuteClick = async () => {
    const muted = await vimeoPlayer.getMuted();
    setVideoMuted(muted);
    vimeoPlayer.setMuted(!muted);
  };

  return (
    <div>
      <div className="page page--full page--center">
        <div className="vimeo" ref={ref}>
          <div className="vimeo__player">
            <Vimeo
              id="vimeo-video"
              ref={vimeoRef}
              autoplay
              muted
              video="484140157"
              responsive
              onReady={(vp) => {
                setVimeoPlayer(vp);
              }}
              onPlay={() => {
                setLoading(false);
              }}
            />
            {vimeoPlayer && showMuted && false && (
              <button type="button" className="vimeo__button" onClick={handleOnMuteClick}>
                {`Sound ${videoMuted ? 'off' : 'on'}`}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  onMount: () => {
    dispatch(pageMount('watch'));
  },
  onPageWatchLoaded: () => {
    dispatch(pageWatchLoaded());
  },
}))(WatchPage);
